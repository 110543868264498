.glitch-wrapper {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  // &>p {
  //   font-weight: bold;
  //   font-size: 2.5rem;
  //   line-height: 2.5rem;
  //   text-align: center;
  //   text-transform: uppercase;
  // }
}

.between-glitch-and-zoom {
  animation: zoom-anim1 2s linear;
}

@keyframes zoom-anim1 {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(1.1);
  }
}

.zoomIn {
  animation: zoom-anim2 .8s linear;
}

@keyframes zoom-anim2 {
  from {
    transform: scale(1.1, 1.1);
    opacity: 1;
  }
  to {
    transform: scale(2, 2);
    opacity: 0;
  }
}

.glitch {
  @offset1: 2px;
  @offset2: -2px;
  @highlight1: #7500C0;
  @highlight2: spin(@highlight1, 180);
  
  // color: white;
  color: #000;
  font-size: 150px;
  text-transform: upercase;
  position: relative;
  display: inline-block;
  
  &::before,
  &::after  {
    content: attr(data-text);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    // background: black;
  }
  
  &::before {
    left: @offset1;
    text-shadow: -2px 0 @highlight1;
    clip: rect(24px, 550px, 90px, 0);
    animation: glitch-anim-2 3s linear alternate-reverse;
  }
  
  &::after {
    left: @offset2;
    text-shadow: -2px 0 @highlight2;
    clip: rect(85px, 550px, 140px, 0);
    animation: glitch-anim 2.5s linear alternate-reverse;
  }
}

.glitch-frames (@n: 20, @index: 0) when (@index <= @n) {
  @keyframeSel: percentage(@index/@n);
  @rand1: unit(round(`Math.random()*150`),px);
  @rand2: unit(round(`Math.random()*150`), px);
  @{keyframeSel} {
    clip: rect(@rand1, 9999px, @rand2, 0);
  }
  .glitch-frames(@n, (@index + 1));
}

@keyframes glitch-anim {
  .glitch-frames(24);
}

@keyframes glitch-anim-2 {
  .glitch-frames(30,2);
}
