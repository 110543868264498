.modal-root-container {
  /* position: absolute;
  border: 1px solid black;
  top: 10rem;
  left: 36rem;
  height: 23rem;
  width: 45rem; */
  position: absolute;
  width: 50rem;
  /* height: 28rem; */
  height: 37rem;
  left: 38rem;
  top: 9rem;
  z-index: 777;
  border: 2px solid #A100FF;
  border-radius: 10%;
  overflow: hidden;
  background: #fff;
  /* z-index: 9999999999999999999999999999999999999999; */
}

.modal-root-container:focus {
  outline: 0;
}

/* 
.modal-modal {
  position: absolute;
  top: 10rem;
  left: 79rem;
  right: 79rem;
  overflow: hidden;
  border-radius: 4px;
  outline: none;
  height: 26rem;
  overflow: hidden;
} */
